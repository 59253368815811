import { CheckIcon } from '@heroicons/react/20/solid'
import Video from '../img/bg_v2.mp4'

// Array de servicios
const services = [
  {
    name: 'Automatización de procesos',
    description:
      'Digitalizamos cualquier proceso o identificamos oportunidades para su digitalización, logrando ahorros de hasta un 60% de tiempo en tareas repetitivas.',
    benefits: [
      'Mejora continua',
      'Optimización de recursos',
      'Reducción de errores humanos',
    ],
  },
  {
    name: 'Integraciones',
    description:
      'Realizamos cualquier tipo de integración: SII, medios de pago, CRM, Google Sheets y más. Conecta tu negocio de forma ágil y segura. Además, optimizamos el flujo de datos y evitamos la duplicación de procesos.',
    benefits: [
      'Ahorro de tiempo',
      'Flujo de datos centralizado',
      'Mejor experiencia de usuario',
    ],
  },  
  {
    name: 'Desarrollo de E-commerce',
    description:
      'Creamos tiendas con diseño profesional y atractivo. Ofrecemos autoadministración completa de tu sitio e integramos pagos nacionales e internacionales.',
    benefits: [
      'Diseño personalizado',
      'Procesos de pago seguros',
      'Escalabilidad global',
    ],
  },
  {
    name: 'Plataformas de academias online',
    description:
      'Amplia experiencia en el desarrollo de academias virtuales, integrando pagos locales e internacionales, con espacios de aprendizaje dinámicos.',
    benefits: [
      'Monetización de cursos',
      'Gestión de usuarios y contenidos',
      'Experiencia de aprendizaje única',
    ],
  },
]

export default function Servicios() {
  return (
    <div className="relative bg-white py-12 sm:py-24">
      {/* Fondo de video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover video-background"
      >
        <source src={Video} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>

      {/* Contenido */}
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
        </div>
        <h2 className="text-4xl font-bold text-gray-900 text-center">
            Servicios
          </h2>
        {/* Párrafo con mensaje más claro */}
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Optimiza procesos internos, conecta tus sistemas y potencia tus ventas en línea. 
        </p>

        {/* Tarjetas de servicios */}
        <div className="isolate mx-auto mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {services.map((service) => (
            <div
              key={service.name}
              className="relative flex flex-col justify-between h-full rounded-3xl p-8 xl:p-10 bg-white bg-opacity-75 ring-1 ring-gray-200"
            >
              {/* Contenido principal: título, descripción y benefits */}
              <div>
                <h3
                  id={service.name}
                  className="text-lg font-semibold leading-8 text-gray-900"
                >
                  {service.name}
                </h3>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {service.description}
                </p>

                {service.benefits && service.benefits.length > 0 && (
                  <ul
                    role="list"
                    className="mt-6 space-y-3 text-sm leading-6 text-gray-600"
                  >
                    {service.benefits.map((item) => (
                      <li key={item} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Botón al final para alinear en la misma posición */}
              <div className="mt-6">
                <a
                  href="#contacto"
                  aria-describedby={service.name}
                  className="block w-full rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Contactar!
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
