import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'


export default function Caracteristicas() {
  return (
    <div className="bg-white py-12 sm:py-24">
      <div className="mx-auto max-w-full px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        Nos sumergimos en tu negocio para ofrecer una solución eficaz
        </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            En <strong>digtmo</strong> creamos soluciones tecnológicas que impulsan
            el crecimiento de nuestros clientes a través de software, e-commerce
            e integración de sistemas. Nos sumergimos en su negocio para entenderlo
            a fondo, pues una visión clara permite desarrollar soluciones de excelencia.
            Acompañamos a cada organización desde la identificación de oportunidades
            hasta la implementación de estrategias basadas en análisis de datos e
            inteligencia de negocio, asegurando que cada proyecto se adapte a sus
            necesidades específicas.
          </p>
        </div>
      </div>
    </div>
  )
}
