import React from 'react'
import lafloresta from './../img/laflorestaiphone.png'
import vistamarconi from './../img/vistamarconi.png'
import econotrans from './../img/econotrans.png'
import avanxa from './../img/avanxa.png'

// Datos de proyectos (ejemplo)
const projects = [
  {
    id: 1,
    title: 'Econotrans',
    description: `Desarrollo e implementación de una plataforma web para gestionar reservas y generar cotizaciones, que incluye un panel de administración para manejar reservas, elaborar cotizaciones y visualizar información detallada.`,
    technologies: ['Node.js', 'Next.js', 'Tailwind', 'Google', 'Socket'],
    imageUrl: econotrans,
    backgroundColor: 'bg-pink-100',
  },
  {
    id: 2,
    title: 'Vista Marconi',
    description: `Plataforma para administrar arrendatarios y generar cobros de arriendo 
      y gastos comunes, integrados con un sistema de facturación y pagos`,
    technologies: ['Node.js', 'React', 'Tailwind', 'Socket'],
    imageUrl: vistamarconi,
    backgroundColor: 'bg-blue-100',
  },
  {
    id: 3,
    title: 'Avanxa',
    description: `Desarrollo de una plataforma integrada con el LMS Open edX, diseñada para generar informes de ventas, administrar encuestas y gestionar diversos procesos internos.`,
    technologies: ['Node.js', 'React', 'Tailwind', 'Socket', 'WooCommerce'],
    imageUrl: avanxa,
    backgroundColor: 'bg-blue-100',
  },
  
  {
    id: 4,
    title: 'La Floresta',
    description: `Implementación de e-commerce para la venta en línea de flores con delivery, además de la integración para seguimiento de pedidos 
      y automatización de procesos post venta.`,
    technologies: ['WordPress', 'WooCommerce'],
    imageUrl: lafloresta,
    backgroundColor: 'bg-gray-100',
  },
]

export default function ProyectosRecientes() {
  return (
    <section className="py-16 px-6 lg:px-8 bg-white">
      <div className="max-w-5xl mx-auto">
        {/* Título de la sección */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900">
            Proyectos recientes
          </h2>
          <p className="text-gray-500 mt-2">
            Algunos de los proyectos que hemos realizado
          </p>
        </div>

        {/* Listado de proyectos */}
        <div className="space-y-16">
          {projects.map((project) => (
            <div
              key={project.id}
              className="flex flex-col lg:flex-row items-center gap-8"
            >
              {/* Imagen con fondo de color suave */}
              <div
                className={`flex-shrink-0 w-full lg:w-1/2 rounded-2xl overflow-hidden ${project.backgroundColor} flex items-center justify-center p-4`}
              >
                <img
                  src={project.imageUrl}
                  alt={project.title}
                  className={`w-full h-auto rounded-md shadow-md ${project.id === 1 || project.id === 2  || project.id === 3 ? 'filter blur-[1px]' : ''}`}
                />
              </div>

              {/* Contenido de texto */}
              <div className="lg:w-1/2">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {project.title}
                </h3>
                <p className="text-gray-700 mb-6 leading-relaxed">
                  {project.description}
                </p>
                <div className="flex items-center gap-4 flex-wrap">
                  <span className="font-semibold text-gray-600">Tecnologías:</span>
                  <ul className="flex items-center gap-2 flex-wrap">
                    {project.technologies.map((tech) => (
                      <li
                        key={tech}
                        className="text-gray-600 text-sm bg-gray-100 px-2 py-1 rounded-md"
                      >
                        {tech}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
