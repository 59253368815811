import Logo from '../img/Logoletrablanca.png';

export default function QuienesSomos() {
  return (
    <div className="bg-white pb-16 pt-24 sm:pb-24 sm:pt-32 xl:pb-32">
      <div className="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch pb-20 sm:pb-24 xl:pb-0">

        <div className="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
          <p className="mt-6 text-xl leading-8 text-gray-700 sm:text-2xl sm:leading-9">
            En <strong>digtmo</strong> creamos soluciones tecnológicas que impulsan 
            el crecimiento de nuestros clientes a través de software, comercio electrónico 
            e integración de sistemas. Nos sumergimos en tu negocio para entenderlo a fondo, 
            porque una visión clara permite desarrollar soluciones de excelencia. Acompañamos 
            a cada organización desde la identificación de oportunidades hasta la implementación 
            de estrategias basadas en análisis de datos e inteligencia de negocio, 
            asegurando que cada proyecto se adapte a sus necesidades específicas.
          </p>
        </div>
      </div>
    </div>
  );
}
